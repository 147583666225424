<template>
  <div class="register-container">
    <v-row class="ma-0 ma-sm-auto">
      <v-col cols="12" offset-lg="2" lg="8" class="pa-0 pa-sm-auto">
        <v-card color="#f2f3f5" flat>
          <v-card-text>
            <h3 class="mt-5">ข้อมูลลูกค้า / Customer Information</h3>
            <v-form
              class="mt-8"
              v-model="valid"
              :lazy-validation="false"
              @submit.prevent="submitForm"
            >
              <v-row>
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="formData.title"
                    :items="titleItems"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    readonly
                    color="black"
                    background-color="white"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      คำนำหน้า / Title
                      <span class="red--text">*</span>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="ชื่อ / Name"
                    outlined
                    dense
                    readonly
                    color="black"
                    background-color="white"
                    v-model="formData.firstName"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      ชื่อ / First Name
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="นามสกุล / Last Name"
                    outlined
                    dense
                    readonly
                    color="black"
                    background-color="white"
                    v-model="formData.lastName"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      นามสกุล / Last Name
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>
                    เพศ / Gender
                    <span class="red--text">*</span>
                  </div>
                  <v-radio-group
                    :readonly="disableField.gender"
                    v-model="formData.gender"
                    class="mt-0"
                    row
                    :rules="[rules.required]"
                  >
                    <v-radio label="ชาย / Men" value="men" />
                    <v-radio label="หญิง / Women" value="women" />
                    <v-radio label="อื่นๆ" value="other" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <BirthDateInput
                    label="วันเดือนปีเกิด / Date of Birth"
                    placeholder="DDMMYYYY"
                    format="DD-MM-YYYY"
                    persistentPlaceholder
                    :value="formData.birthDate"
                    @input="updateDateInput"
                    @clear="onClear"
                    :rules="[rules.required]"
                    :disabled="disableField.birthDate"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    persistent-placeholder
                    placeholder="000-000-0000"
                    outlined
                    dense
                    readonly
                    color="black"
                    background-color="white"
                    @keypress="validateNumeric"
                    v-model="formData.phoneNumber"
                    :rules="[rules.required, rules.max]"
                  >
                    <template v-slot:label>
                      เบอร์โทรศัพท์ / Mobile Phone No.
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="อีเมล / E-mail Address"
                    persistent-placeholder
                    placeholder="Example@mail.com"
                    outlined
                    dense
                    :readonly="disableField.email"
                    color="black"
                    background-color="white"
                    v-model="formData.email"
                    type="email"
                    :rules="[rules.emailValidate]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport"
                    persistent-placeholder
                    :readonly="disableField.citizenId"
                    placeholder="เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.citizenId"
                    :rules="[rules.citizenValidate]"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.country"
                    :items="countryList"
                    persistent-placeholder
                    :readonly="disableField.country"
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                  >
                    <template v-slot:label> ประเทศ / Country </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="ทะเบียนรถ / License Plate No."
                    persistent-placeholder
                    :readonly="disableField.vehicleRegistrationNo"
                    placeholder="ทะเบียนรถ / License Plate No."
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.vehicleRegistrationNo"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>สถานภาพ / Marital Status</div>
                  <v-radio-group
                    :readonly="disableField.maritalStatus"
                    v-model="formData.maritalStatus"
                    class="mt-0"
                    row
                  >
                    <v-radio label="โสด / Single" :value="false" />
                    <v-radio label="สมรส / Married" :value="true" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>การศึกษา</div>
                  <v-radio-group
                    :readonly="disableField.education"
                    v-model="formData.education"
                    class="mt-0"
                    row
                  >
                    <v-radio label="ประถมศึกษา" value="primary" />
                    <v-radio label="มัธยมศึกษา" value="high school" />
                    <v-radio label="ปริญญาตรี" value="bachelor" />
                    <v-radio label="สูงกว่าปริญญาตรี" value="higher bachelor" />
                    <v-radio label="อื่นๆ" value="other" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div>รายได้ต่อครัวเรือน / เดือน</div>
                  <v-radio-group
                    :readonly="disableField.incomeRate"
                    v-model="formData.incomeRate"
                    class="mt-0"
                    row
                  >
                    <v-radio label="ต่ำกว่า 15,000 บาท" value="<15000" />
                    <v-radio label="15,000 - 30,000 บาท" value="15000-30000" />
                    <v-radio label="30,001 - 50,000 บาท" value="30001-50000" />
                    <v-radio label="50,001 - 70,000 บาท" value="50001-70000" />
                    <v-radio
                      label="70,001 - 100,000 บาท"
                      value="70001-100000"
                    />
                    <v-radio label="100,000 บาทขึ้นไป" value=">100000" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <hr />
              <h3 class="mt-5">ที่อยู่ลูกค้า / Customer Address</h3>
              <v-row class="mt-6">
                <v-col cols="12" md="4">
                  <v-text-field
                    label="บ้านเลขที่ / House No."
                    persistent-placeholder
                    :readonly="disableField.houseNo"
                    placeholder="บ้านเลขที่ / House No."
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.houseNo"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="ซอย / Lane"
                    persistent-placeholder
                    :readonly="disableField.lane"
                    placeholder="ซอย / Lane"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.lane"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="หมู่ / Village No."
                    persistent-placeholder
                    :readonly="disableField.villageNo"
                    placeholder="หมู่ / Village No."
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.villageNo"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="ถนน / Road"
                    persistent-placeholder
                    :readonly="disableField.road"
                    placeholder="ถนน / Road"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.road"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="หมู่บ้าน/อาคาร / Village/Building"
                    persistent-placeholder
                    :readonly="disableField.village"
                    placeholder="หมู่บ้าน/อาคาร / Village/Building"
                    outlined
                    dense
                    color="black"
                    background-color="white"
                    v-model="formData.village"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.postalCode"
                    :items="postalCodeList"
                    persistent-placeholder
                    placeholder="00000"
                    :item-value="
                      (item) => {
                        return item
                      }
                    "
                    :item-text="
                      (item) => {
                        return `${item.postcode} ${item.subDistrict} ${item.district} ${item.province}`
                      }
                    "
                    outlined
                    dense
                    :readonly="disableField.postalCode"
                    color="black"
                    background-color="white"
                    @keypress="validateNumeric"
                    @change="changePostalCode"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      รหัสไปรษณีย์ / Postal Code
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.province"
                    :items="provinceList"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :readonly="disableField.province"
                    color="black"
                    background-color="white"
                    @change="inputProvince"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      จังหวัด / Province
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="formData.district"
                    :items="districtList"
                    persistent-placeholder
                    placeholder="กรุณาเลือก"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    :readonly="disableField.district"
                    color="black"
                    background-color="white"
                    @change="inputDistrict"
                    :rules="[rules.required]"
                  >
                    <template v-slot:label>
                      อำเภอ / เขต / District
                      <span class="red--text">*</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <hr />
              <div
                class="d-flex check-box-input mt-3"
                v-if="mktConsentDetail !== null"
              >
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="formData.mktconsent"
                  :readonly="disableMkt"
                  hide-details
                />
                <span>
                  ฉันต้องการรับข้อมูลข่าวสารทางการตลาด
                  <br />
                  <span
                    class="cursor-pointer primary--text"
                    @click.stop="toggleMarket"
                  >
                    นโยบายการรับข้อมูลข่าวสารทางการตลาด
                  </span>
                </span>
              </div>
              <div v-if="consentList !== null">
                <div
                  class="d-flex check-box-input mt-2"
                  v-for="(item, index) in consentList"
                  :key="index"
                >
                  <v-checkbox
                    class="mt-0 pt-0"
                    v-model="item.check"
                    hide-details
                    :readonly="item.disable"
                    :rules="[rules.required]"
                  />
                  <span>
                    ข้าพเจ้าได้อ่านและยอมรับ
                    <span
                      class="cursor-pointer primary--text"
                      @click.stop="getTermCon(index)"
                    >
                      ข้อตกลงและเงื่อนไข
                    </span>
                    สมาชิก เดอะ สตรีท พอยท์
                  </span>
                </div>
              </div>
              <div class="remark">
                * สมาชิก The Street Point สามารถดูคะแนนสะสมได้ที่ <br />LINE
                OFFICIAL ACCOUNT
                <a color="primary" href="line://ti/p/@thestreetratchada"
                  >@thestreetratchada</a
                >
              </div>
              <div class="remark">
                โปรดศึกษา
                <a
                  color="primary"
                  href="https://www.thestreetratchada.com/upload/pdpa/2-customer.pdf"
                  target="_blank"
                  >นโยบายความเป็นส่วนตัวสำหรับลูกค้า</a
                >
              </div>
              <hr />
              <v-row>
                <v-col cols="12" sm="4" md="3" lg="2">
                  <v-btn
                    class="mt-4"
                    color="#f7941e"
                    type="submit"
                    block
                    :disabled="!valid"
                  >
                    บันทึก
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <TermCon
      :modal="termModal"
      :toggle="toggleTermCon"
      :detail="consentDetail"
    />
    <Market
      :modal="marketModal"
      :toggle="toggleMarket"
      :detail="mktConsentDetail"
    />
  </div>
</template>

<script>
import { forEach, findIndex, size } from 'lodash'

import BirthDateInput from '@/components/BirthDateInput'

import Market from '../Register/Market.vue'
import TermCon from '../Register/TermCon.vue'

import Utils from '@/mixin'

import {
  checkConsent,
  getConsent,
  checkMktConsent,
  getMktConsent,
} from '@/middleware/Auth'

import {
  getCountry,
  getDistrict,
  getProvince,
  getPostcodes,
} from '@/middleware/Auth'

export default {
  mixins: [Utils],
  components: {
    BirthDateInput,
    Market,
    TermCon,
  },
  computed: {
    countryList() {
      return this.countryItems
    },
    postalCodeList() {
      return this.postalCodeItems
    },
    districtList() {
      return this.districtItems
    },
    provinceList() {
      return this.provinceItems
    },
  },
  data: () => ({
    valid: false,
    countryItems: [],
    provinceItems: [],
    districtItems: [],
    postalCodeItems: [],
    titleItems: [
      { value: 'mr', name: 'นาย' },
      { value: 'ms', name: 'นาง' },
      { value: 'mrs', name: 'นางสาว' },
    ],
    formData: {
      title: '',
      firstName: '',
      lastName: '',
      birthDate: null,
      gender: null,
      phoneNumber: '',
      email: '',
      postalCode: '',
      province: '',
      district: '',
      country: '',
      citizenId: '',
      vehicleRegistrationNo: '',
      maritalStatus: null,
      education: null,
      incomeRate: null,
      houseNo: '',
      lane: '',
      village: '',
      villageNo: '',
      road: '',
    },
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (email === '' || re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
      max: (value) => (!!value && value.length === 10) || 'Invalid format.',
      citizenValidate: (value) => {
        const numberValidate = /^[0-9]+$/ //eslint-disable-line
        if (
          value === '' ||
          (numberValidate.test(value) && value.length === 13)
        ) {
          return true
        }
        return 'Invalid format.'
      },
    },
    disableField: {
      email: false,
      gender: false,
      citizenId: false,
      country: false,
      vehicleRegistrationNo: false,
      maritalStatus: false,
      education: false,
      incomeRate: false,
      houseNo: false,
      lane: false,
      village: false,
      villageNo: false,
      road: false,
      birthDate: false,
      postalCode: false,
      province: false,
      district: false,
    },
    disableMkt: false,
    marketModal: false,
    termModal: false,
    consentList: null,
    consentDetail: null,
    mktConsentDetail: null,
  }),
  mounted() {
    this.getInitialData()
  },
  methods: {
    async getInitialData() {
      window.loading(true)
      await this.getUserProfile()
      await this.getInitialDataConsent()
      const country = await getCountry({ listType: 'all' })
      const district = await getDistrict({ listType: 'all' })
      const province = await getProvince({ listType: 'all' })
      const postcode = await getPostcodes({ listType: 'all' })
      this.countryItems = country
      this.provinceItems = province
      this.districtItems = district
      this.postalCodeItems = postcode
      window.loading(false)
    },
    async getInitialDataConsent() {
      const consent = await getConsent()
      const mktConsent = await getMktConsent()
      const checkTerm = await checkConsent()
      const checkMkt = await checkMktConsent()
      let newConsent = []
      forEach(consent, (i) => {
        if (size(checkTerm) > 0) {
          forEach(checkTerm, (j) => {
            if (i.id === j.id)
              newConsent.push({ ...i, check: false, disable: false })
            else newConsent.push({ ...i, check: true, disable: true })
          })
        } else {
          newConsent.push({ ...i, check: true, disable: true })
        }
      })
      this.consentList = newConsent
      this.mktConsentDetail = mktConsent
      if (checkMkt === null) {
        this.formData.mktconsent = true
      }
    },
    getTermCon(index) {
      const { consentList } = this
      this.consentDetail = consentList[index]
      this.toggleTermCon()
    },
    toggleTermCon() {
      this.termModal = !this.termModal
    },
    toggleMarket() {
      this.marketModal = !this.marketModal
    },
    async changePostalCode() {
      const { postalCode } = this.formData
      const district = await getDistrict({
        listType: 'all',
        provinceId: postalCode?.provinceId,
      })
      this.districtItems = district
      this.formData = {
        ...this.formData,
        province: { name: postalCode?.province, id: postalCode?.provinceId },
        district: { name: postalCode?.district, id: postalCode?.districtId },
      }
    },
    async inputProvince() {
      const { province } = this.formData
      const district = await getDistrict({
        listType: 'all',
        provinceId: province,
      })
      this.districtItems = district
      this.formData = {
        ...this.formData,
        district: '',
        postalCode: '',
      }
    },
    async inputDistrict() {
      this.formData = {
        ...this.formData,
        postalCode: '',
      }
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    onClear() {
      this.formData.birthDate = null
    },
    updateDateInput(e) {
      this.formData.birthDate = e
    },
    async submitForm() {
      window.loading(true)
      try {
        const res = await this.updateProfile()
        let text = ''
        window.loading(false)
        const json = await res.json()
        if (res.status === 200) {
          await this.getUserProfile()
          this.$router.push({ name: 'Point-History' })
        } else {
          switch (json.message) {
            case 'Duplicate email':
              text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
              break
            case 'Duplicate phone number':
              text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
              break
            case 'Invalid data':
              text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
              break
            default:
              break
          }
          if (text !== '') {
            alert(text)
          }
        }
      } catch (err) {
        window.loading(false)
        console.log(err)
        let text = ''
        switch (err.message) {
          case 'This email already registered: register error':
            text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'This phone number already registered: register error':
            text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'Invalid data':
            text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
            break
          default:
            break
        }
        alert(text)
      }
    },
    async updateProfile() {
      const { formData } = this
      let submitForm = {
        email: formData.email,
        gender: formData.gender,
        citizenId: formData.citizenId,
        maritalStatus: formData.maritalStatus,
        countryId: formData.country === '' ? null : formData.country,
        education: formData.education,
        incomeRate: formData.incomeRate,
        vehicleRegistrationNo: formData.vehicleRegistrationNo,
        birthDate: formData.birthDate,
        address: {
          type: 'current',
          houseNo: formData.houseNo,
          lane: formData.lane,
          village: formData.village,
          villageNo: formData.villageNo,
          road: formData.road,
          subDistrictId: formData.postalCode?.subDistrictId,
          districtId: formData.district?.id,
          provinceId: formData.province?.id,
          postcode: formData.postalCode?.postcode,
        },
        mktconsent: formData.mktconsent || false,
      }
      return await this.updateCustomerProfile(submitForm)
    },
  },
  watch: {
    userProfile: {
      handler() {
        const {
          birthDate,
          email,
          firstName,
          phone,
          gender,
          lastName,
          title,
          addresses,
          flg_mkt_consent,
          citizenId,
          countryId,
          vehicleRegistrationNo,
          maritalStatus,
          education,
          incomeRate,
        } = this.userProfile

        const check = !!(
          birthDate &&
          firstName &&
          phone &&
          gender &&
          lastName &&
          title &&
          addresses
        )
        if (check) {
          this.$router.push({ name: 'Point-History' })
        }

        const { titleItems } = this
        const index = findIndex(titleItems, (i) => (i.value = title))
        let postalCode = ''

        if (addresses) {
          postalCode = {
            district: addresses[0].district.name,
            districtId: addresses[0].district.id,
            postcode: addresses[0].postcode,
            province: addresses[0].province.name,
            provinceId: addresses[0].province.id,
            subDistrict: addresses[0].subDistrict.name,
            subDistrictId: addresses[0].subDistrict.id,
          }
        }
        this.formData = {
          title: titleItems[index],
          firstName,
          lastName,
          birthDate,
          gender,
          phoneNumber: phone,
          email: email ? email : '',
          postalCode: addresses ? postalCode : '',
          province: addresses ? addresses[0].province : '',
          district: addresses ? addresses[0].district : '',
          citizenId,
          country: countryId ? countryId : '',
          vehicleRegistrationNo: vehicleRegistrationNo
            ? vehicleRegistrationNo
            : '',
          maritalStatus,
          education,
          incomeRate,
          houseNo: addresses ? addresses[0].houseNo : '',
          lane: addresses ? addresses[0].lane : '',
          village: addresses ? addresses[0].village : '',
          villageNo: addresses ? addresses[0].villageNo : '',
          road: addresses ? addresses[0].road : '',
        }
        this.disableField = {
          email: email !== '' && email !== null ? true : false,
          gender: gender !== null ? true : false,
          citizenId: citizenId !== '' && citizenId !== null ? true : false,
          country: countryId !== null ? true : false,
          vehicleRegistrationNo: vehicleRegistrationNo !== '' ? true : false,
          maritalStatus: maritalStatus !== null ? true : false,
          education: education !== null ? true : false,
          incomeRate: incomeRate !== null ? true : false,
          houseNo: addresses && addresses[0].houseNo !== '' ? true : false,
          lane: addresses && addresses[0].lane !== '' ? true : false,
          village: addresses && addresses[0].village !== '' ? true : false,
          villageNo: addresses && addresses[0].villageNo !== '' ? true : false,
          road: addresses && addresses[0].road !== '' ? true : false,
          birthDate: birthDate !== null ? true : false,
          postalCode: addresses !== undefined ? true : false,
          province: addresses !== undefined ? true : false,
          district: addresses !== undefined ? true : false,
        }
        this.disableMkt = flg_mkt_consent
      },
      deep: false,
    },
  },
}
</script>

<style lang="scss" scoped>
$black: #000;
$grey: rgba(0, 0, 0, 0.6);

.register-container {
  background: url('../../assets/bg.svg');
  background-size: cover;
  height: 100%;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
  .v-card {
    .v-card__text {
      color: $black;
      @media (min-width: 768px) {
        padding: 64px 115px;
      }
      a {
        text-decoration: none;
        text-transform: lowercase;
      }
      .check-box-input {
        color: $grey;
        .cursor-pointer {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
